<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__title">
        <span>Escolha uma Imagem</span>
      </div>
      <div class="action">
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </div>
    </div>

    <div v-if="!isLoading" class="modal__body">
      <template>
        <div class="modal__body--actions mb-2">
          <z-btn
            v-if="
              !imagesToRemove.length && !selectSeveral && !removeSilgleLoading
            "
            small
            rounded
            :text="!selectSeveral ? 'Selecionar imagens' : 'Cancelar seleção'"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            primary
            @click="selectSeveral = !selectSeveral"
          />
          <div
            v-if="imagesToRemove.length || selectSeveral"
            class="remove-block"
          >
            <z-btn
              small
              rounded
              color="#d21938"
              :text="buttonText"
              icon="$delete_rounded"
              :is-loading="deleteImageLoading"
              :disabled="!this.imagesToRemove.length"
              @click="deleteSelectedImage"
            />
            <v-icon
              color="#d21938"
              @click="
                (imageToRemove = null),
                  (imagesToRemove = []),
                  (selectSeveral = !selectSeveral)
              "
            >
              $cancel</v-icon
            >
          </div>
          <z-btn
            v-else-if="!removeSilgleLoading"
            small
            rounded
            text="Upload de imagem"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            primary
            icon="$image_rounded"
            @click="openImagePicker"
          />
          <strong v-else style="color: #d21938"
            >Removendo imagem, aguarde...</strong
          >

          <input
            @change="uploadImage"
            type="file"
            multiple
            ref="imageFile"
            accept="image/*"
            style="display: none"
          />
        </div>
        <div class="modal__body__gallery" v-if="repoImages.length">
          <div
            v-for="(item, i) in repoImages"
            :key="i"
            class="modal__body__gallery__item"
          >
            <template v-for="(item1, j) in item">
              <v-checkbox
                v-if="selectSeveral"
                :key="j + item1"
                dense
                small
                hide-details
                @change="chooseIfIsToInsertOrRemoveImage($event, item1)"
              >
              </v-checkbox>
              <ImageGallery
                :key="j"
                :image-path="item1"
                :images-to-delete-prop="imagesToRemove"
                :is-to-select-many-prop="selectSeveral"
                @copied="copyToClipBoard"
                @removeItem="setImagesToRemove"
                @removeFromDelete="removeFromDelete"
                @removeSingle="removeSingleImage"
                class="img"
              />
            </template>
            <!-- <ImageGallery
              v-for="(item1, j) in item"
              :key="j"
              :image-path="item1"
              :images-to-delete-prop="imagesToRemove"
              @copied="copyToClipBoard"
              @removeItem="setImagesToRemove"
              @removeFromDelete="removeFromDelete"
              @removeSingle="removeSingleImage"
              class="img"
            /> -->
          </div>
        </div>
        <div class="load-info" v-else>
          <strong>Nenhuma imagem cadastrada.</strong>
        </div>
      </template>
    </div>
    <div v-else class="modal__body load-info">
      <z-loading />
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
import ImageGallery from "./ImageGallery.vue";
export default {
  data() {
    return {
      selectSeveral: false,
      removeSilgleLoading: false,
      http: new ApiClient(),
      imageToRemove: "",
      imagesToRemove: [],
      deleteImageLoading: false,
      repoImages: [],
      repoImagesUnity: [],
      isLoading: false,
    };
  },
  props: {
    // imageRepoProp: { type: [Array, Object], default: null, required: true },
  },
  components: {
    ImageGallery,
  },
  computed: {
    buttonText() {
      const TEXT = !this.imagesToRemove.length
        ? "Selecione as imagens"
        : this.imagesToRemove.length == 1
        ? "Remover a imagem selecionada"
        : "Remover as imagens selecionadas";

      return TEXT;
    },
  },
  created() {
    this.loadRepoImages();
  },
  methods: {
    removeFromDelete(image) {
      const IDX = this.imagesToRemove.findIndex((d) => d === image);
      this.imagesToRemove.splice(IDX, 1);
    },
    copyToClipBoard(info) {
      let input = document.createElement("input");
      input.setAttribute("value", info);
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(input.value);
      document.body.removeChild(input);

      window.parent.postMessage({
        type: 'clipboardData',
        data: input.value
      }, '*');
    },
    chooseIfIsToInsertOrRemoveImage(evt, path) {
      if (evt) this.setImagesToRemove(path);
      else this.removeFromDelete(path);
    },
    setImagesToRemove(img) {
      this.imageToRemove = img;
      this.imagesToRemove.push(img);
    },
    async openImagePicker() {
      this.$refs.imageFile.click();
    },
    async removeSingleImage(path) {
      try {
        this.removeSilgleLoading = true;
        const res = await this.http.post("s3/removeTemplateImage", {
          filename: path,
        });
        if (res) {
          this.$toast.success("Imagem apagada com sucesso");
          this.removeSilgleLoading = false;
          this.loadRepoImages();
        }
        this.removeSilgleLoading = false;
      } catch (error) {
        this.$toas.error("Erro ao apagar imagem!");
        this.removeSilgleLoading = false;
      }
    },
    async deleteSelectedImage() {
      const ITENS = [];
      const PROMISES = [];

      this.imagesToRemove.forEach((item) => {
        ITENS.push({
          filename: item,
        });
      });

      ITENS.forEach((item) => {
        const D = this.http.post("s3/removeTemplateImage", item);
        PROMISES.push(D);
      });

      this.deleteImageLoading = true;
      Promise.all(PROMISES)
        .then((results) => {
          if (results) {
            this.deleteImageLoading = false;
            this.$toast.success("Operação realizada com sucesso!");
            this.imagesToRemove = [];
            this.selectSeveral = !this.selectSeveral;
            this.loadRepoImages();
          }
          this.deleteImageLoading = false;
        })
        .catch(() => {
          this.$toast.error("Erro ao remover imagens");
          this.deleteImageLoading = false;
        });

      // try {
      //   this.deleteImageLoading = true;
      //   const req = await this.http.post("s3/removeTemplateImage", {
      //     filename: this.imageToRemove,
      //   });

      //   if (req) {
      //     this.$toast.success("Imagem removida com sucesso");
      //     this.deleteImageLoading = false;
      //     this.imageToRemove = null;
      //     this.loadRepoImages();
      //   }
      // } catch (error) {
      //   this.$toast.error("Erro ao apagar imagem selecionada");
      //   this.deleteImageLoading = false;
      // }
    },
    async uploadImage(event) {
      const $context = this;

      const arr = Array.from(event.target.files);
      arr.forEach((file) => {
        var reader = new FileReader();
        reader.onload = async () => {
          await $context.http
            .post("s3/addTemplateImages", {
              base64: reader.result,
              filename: `${new Date().toISOString()}_${file.name}`,
            })
            .then(() => {
              $context.loadRepoImages();
            });
        };
        reader.readAsDataURL(file);
      });
    },

    async loadRepoImages() {
      this.isLoading = true;
      const $context = this;
      this.repoImages = await $context.http.get(
        "s3/getTemplatesImagesByCompanyId"
      );

      const temp = [];

      for (let index = 0; index < this.repoImages.length; index += 3) {
        let arrTemp = [];
        arrTemp.push(
          "https://data-to-act-front.s3.amazonaws.com/" + this.repoImages[index]
        );
        if (index + 1 < this.repoImages.length)
          arrTemp.push(
            "https://data-to-act-front.s3.amazonaws.com/" +
              this.repoImages[index + 1]
          );
        if (index + 2 < this.repoImages.length)
          arrTemp.push(
            "https://data-to-act-front.s3.amazonaws.com/" +
              this.repoImages[index + 2]
          );

        temp.push(arrTemp);
      }

      this.repoImages = temp;

      this.repoImages.forEach((arr) => {
        arr.forEach((path) => {
          this.repoImagesUnity.push(path);
        });
      });
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  padding: $z-s-1 0;
  border-radius: 15px;
  color: $z-black-1;
  z-index: 999 !important;
  &__header {
    padding: 0 $z-s-1 $z-s-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $z-s-1;
    border-bottom: 2px solid #e7e7e7;

    &__title {
      span {
        font-weight: 600;
        line-height: 15px;
        font-size: 1.25rem;
      }
    }
  }

  &__body {
    color: #4e4e4e;
    height: 70vh;
    overflow: hidden;
    margin-bottom: $z-s-1;
    padding: 0 $z-s-1 $z-s-2;
    &--actions {
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5rem;

      .remove-block {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    &__gallery {
      display: flex;
      flex-wrap: wrap;
      gap: 0 1.6rem;
      max-height: 100%;
      overflow: auto;
      @include trackScrollBar;

      &__item {
        flex: 1 1 200px;
        // border: 1px solid blue;

        .img {
          margin: $z-s-1 0 $z-s-2;
          // &:not(:last-child) {
          //   margin: $z-s-1 0 $z-s-2 ;
          //   border: 1px solid blue;
          // }
        }
      }
    }
  }

  .load-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
