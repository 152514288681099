var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal", staticStyle: { "z-index": "9999 !important" } },
    [
      _c("div", { staticClass: "modal__header" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "action" },
          [
            _c(
              "v-icon",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("mdi-close")]
            ),
          ],
          1
        ),
      ]),
      _vm.localTags !== []
        ? _c("div", { staticClass: "modal__body" }, [
            _c(
              "ul",
              _vm._l(_vm.localTags, function (tag, index) {
                return _c("div", { key: index }, [
                  _c("li", [_c("span", [_vm._v("$" + _vm._s(tag))])]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "modal--actions mt-3" },
        [
          _c("z-btn", {
            attrs: { small: "", text: "fechar" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header__title" }, [
      _c("span", [_vm._v("Tags")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }