<template>
  <div class="modal" style="z-index: 9999 !important">
    <div class="modal__header">
      <div class="modal__header__title">
        <span>Upload do template de e-mail</span>
      </div>
      <div class="action">
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </div>
    </div>

    <div class="modal__body" v-if="localTags !== []">
      <h1> Teste</h1>
    </div>


    <div class="modal--actions mt-3">
      <z-btn small text="fechar" @click="$emit('close')" />
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
export default {
  props: {
    tags: { type: Array, default: () => [] },
  },
  data() {
    return {
      nonce: 1,
      y: 0,
      x: 0,
      search: null,
      isLoading: false,
      email: "",
      http: new ApiClient(),
      items: [{ header: "Selecione ou crie um e-mail" }],
      model: [],
      activator: null,
      attach: null,
      colors: [
        "#1976D2",
        "#1976D2",
        "#1976D2",
        "#1976D2",
        "#1976D2",
        "#1976D2",
      ],
      editing: null,
      localTags: [],
      editingIndex: -1,
    };
  },
  created() {
    // this.localTags = this.tags;
  },
  methods: {
    submit() {
      if (
        this.$refs.form.validate() &&
        this.templateToSaveProp.emailSender &&
        this.templateToSaveProp.emailSubject
      ) {
        // if (this.templateToSaveProp && this.choosenConnectionProp) {

        this.emailInformationProp.editor.exportHtml(async (data) => {
          let OBJ = { content: {} };
          OBJ.email = this.formatEmail();
          OBJ.subject = this.templateToSaveProp.emailSubject;
          OBJ.replyTo = this.templateToSaveProp.replyTo;
          OBJ.mailSender = this.templateToSaveProp.emailSender;
          OBJ.senderName = this.templateToSaveProp.senderName;
          OBJ.content.message = JSON.stringify(data);
          //  console.log(OBJ.content.message);
          OBJ.content.message = this.transformString(OBJ.content.message);
          //   console.log(OBJ.content.message);
          OBJ.content.description = "";
          OBJ.content.databaseConfigId = this.choosenConnectionProp?.id;

          try {
            this.isLoading = true;
            const response = await this.http.post(
              `templatesConfiguration/sendEmailTemplateTest`,
              {
                ...OBJ,
              }
            );
            if (response) {
              this.isLoading = false;
              this.$toast.success("Sucesso!");
              this.$emit("close");
            }
          } catch (error) {
            this.$toast.error(`${error.body.detail}`);
            this.isLoading = false;
            throw error;
          }
        });

        // } else {
        //   this.$toast.warning("Preencha todas as informações necessárias");
        //   this.$emit("onErroInInformations");
        // }
      } else {
        this.$toast.error(`Preencha todos os campos de remetente obrigatórios`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  padding: $z-s-1;
  border-radius: 15px;
  color: $z-black-1;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $z-s-1;

    &__title {
      span {
        font-weight: 600;
        text-transform: uppercase;
        line-height: 15px;
        font-size: 1rem;
      }
    }
  }

  &__body {
    color: #4e4e4e;
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
