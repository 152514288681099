var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "figure",
    {
      style: {
        border: _vm.imagesToDeleteProp.includes(_vm.imagePath)
          ? "3px dashed #D21938"
          : "",
      },
    },
    [
      _c("img", { attrs: { src: _vm.imagePath }, on: { click: _vm.copy } }),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.copied,
              expression: "copied",
            },
          ],
        },
        [_vm._v("Copiado!")]
      ),
      !_vm.isToSelectManyProp
        ? _c(
            "div",
            { staticClass: "remove-button" },
            [
              _c(
                "v-icon",
                {
                  attrs: { small: "", color: "white" },
                  on: {
                    click: function ($event) {
                      if ($event.target !== $event.currentTarget) return null
                      return _vm.$emit("removeSingle", _vm.imagePath)
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }