var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "modal__header" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "action" },
        [
          _c(
            "v-icon",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("mdi-close")]
          ),
        ],
        1
      ),
    ]),
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "modal__body" },
          [
            [
              _c(
                "div",
                { staticClass: "modal__body--actions mb-2" },
                [
                  !_vm.imagesToRemove.length &&
                  !_vm.selectSeveral &&
                  !_vm.removeSilgleLoading
                    ? _c("z-btn", {
                        attrs: {
                          small: "",
                          rounded: "",
                          text: !_vm.selectSeveral
                            ? "Selecionar imagens"
                            : "Cancelar seleção",
                          rules: [(v) => !!v || "Campo obrigatório"],
                          primary: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.selectSeveral = !_vm.selectSeveral
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.imagesToRemove.length || _vm.selectSeveral
                    ? _c(
                        "div",
                        { staticClass: "remove-block" },
                        [
                          _c("z-btn", {
                            attrs: {
                              small: "",
                              rounded: "",
                              color: "#d21938",
                              text: _vm.buttonText,
                              icon: "$delete_rounded",
                              "is-loading": _vm.deleteImageLoading,
                              disabled: !this.imagesToRemove.length,
                            },
                            on: { click: _vm.deleteSelectedImage },
                          }),
                          _c(
                            "v-icon",
                            {
                              attrs: { color: "#d21938" },
                              on: {
                                click: function ($event) {
                                  ;(_vm.imageToRemove = null),
                                    (_vm.imagesToRemove = []),
                                    (_vm.selectSeveral = !_vm.selectSeveral)
                                },
                              },
                            },
                            [_vm._v(" $cancel")]
                          ),
                        ],
                        1
                      )
                    : !_vm.removeSilgleLoading
                    ? _c("z-btn", {
                        attrs: {
                          small: "",
                          rounded: "",
                          text: "Upload de imagem",
                          rules: [(v) => !!v || "Campo obrigatório"],
                          primary: "",
                          icon: "$image_rounded",
                        },
                        on: { click: _vm.openImagePicker },
                      })
                    : _c("strong", { staticStyle: { color: "#d21938" } }, [
                        _vm._v("Removendo imagem, aguarde..."),
                      ]),
                  _c("input", {
                    ref: "imageFile",
                    staticStyle: { display: "none" },
                    attrs: { type: "file", multiple: "", accept: "image/*" },
                    on: { change: _vm.uploadImage },
                  }),
                ],
                1
              ),
              _vm.repoImages.length
                ? _c(
                    "div",
                    { staticClass: "modal__body__gallery" },
                    _vm._l(_vm.repoImages, function (item, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "modal__body__gallery__item" },
                        [
                          _vm._l(item, function (item1, j) {
                            return [
                              _vm.selectSeveral
                                ? _c("v-checkbox", {
                                    key: j + item1,
                                    attrs: {
                                      dense: "",
                                      small: "",
                                      "hide-details": "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.chooseIfIsToInsertOrRemoveImage(
                                          $event,
                                          item1
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _c("ImageGallery", {
                                key: j,
                                staticClass: "img",
                                attrs: {
                                  "image-path": item1,
                                  "images-to-delete-prop": _vm.imagesToRemove,
                                  "is-to-select-many-prop": _vm.selectSeveral,
                                },
                                on: {
                                  copied: _vm.copyToClipBoard,
                                  removeItem: _vm.setImagesToRemove,
                                  removeFromDelete: _vm.removeFromDelete,
                                  removeSingle: _vm.removeSingleImage,
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "load-info" }, [
                    _c("strong", [_vm._v("Nenhuma imagem cadastrada.")]),
                  ]),
            ],
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "modal__body load-info" },
          [_c("z-loading")],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header__title" }, [
      _c("span", [_vm._v("Escolha uma Imagem")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }