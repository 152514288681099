var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showInformations,
              expression: "showInformations",
            },
          ],
          staticClass: "wrapper__card",
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "12", md: "12", sm: "12" } },
                [
                  _c(
                    "div",
                    {},
                    [
                      _c(
                        "v-form",
                        { ref: "form" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "5px",
                                            padding: "0px 5px",
                                          },
                                          attrs: { sm: "12", md: "6", lg: "6" },
                                        },
                                        [
                                          _c("z-input", {
                                            attrs: {
                                              rules: [
                                                (v) =>
                                                  !!v || "Campo obrigatório",
                                              ],
                                              hideDetails: true,
                                              placeholder: "Nome do template",
                                              type: "text",
                                            },
                                            model: {
                                              value:
                                                _vm.templateToSave.templateName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.templateToSave,
                                                  "templateName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "templateToSave.templateName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "5px",
                                            padding: "0px 5px",
                                          },
                                          attrs: { sm: "12", md: "6", lg: "6" },
                                        },
                                        [
                                          _c("z-autocomplete", {
                                            attrs: {
                                              label: "Selecione uma conexão",
                                              items: _vm.dbConfigList,
                                              itemText: "name",
                                              placeholder: "Pesquisar",
                                              type: "text",
                                              hideDetails: true,
                                              returnObject: "",
                                              rules: [
                                                (v) =>
                                                  !!v || "Campo obrigatório",
                                              ],
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.chooseCollection()
                                              },
                                            },
                                            model: {
                                              value: _vm.choosenConnection,
                                              callback: function ($$v) {
                                                _vm.choosenConnection = $$v
                                              },
                                              expression: "choosenConnection",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "justify-content": "flex-end",
                                      },
                                    },
                                    [
                                      _vm.isEditing
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                bottom: "",
                                                color: "grey",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "z-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "templateTooltipButton",
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "8px",
                                                                  "margin-bottom":
                                                                    "5px",
                                                                  padding:
                                                                    "5px",
                                                                },
                                                                attrs: {
                                                                  small: "",
                                                                  rules: [
                                                                    (v) =>
                                                                      !!v ||
                                                                      "Campo obrigatório",
                                                                  ],
                                                                  primary: "",
                                                                  icon: "$account_tree",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.confirmationFastJourney = true
                                                                    },
                                                                },
                                                              },
                                                              "z-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                500359367
                                              ),
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "white",
                                                    padding: "0.5rem",
                                                    "font-weight": "500",
                                                  },
                                                },
                                                [_vm._v("Jornada rápida")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "", color: "grey" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "z-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "templateTooltipButton",
                                                          staticStyle: {
                                                            "margin-right":
                                                              "8px",
                                                            "margin-bottom":
                                                              "5px",
                                                            padding: "5px",
                                                          },
                                                          attrs: {
                                                            small: "",
                                                            rules: [
                                                              (v) =>
                                                                !!v ||
                                                                "Campo obrigatório",
                                                            ],
                                                            primary: "",
                                                            icon: "mdi-currency-usd",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.openVariablesModal = true
                                                            },
                                                          },
                                                        },
                                                        "z-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "white",
                                                padding: "0.5rem",
                                                "font-weight": "500",
                                              },
                                            },
                                            [_vm._v("Mapeamento de Variáveis")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "", color: "grey" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "z-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "templateTooltipButton",
                                                          staticStyle: {
                                                            "margin-right":
                                                              "8px",
                                                            "margin-bottom":
                                                              "5px",
                                                            padding: "5px",
                                                          },
                                                          attrs: {
                                                            small: "",
                                                            rules: [
                                                              (v) =>
                                                                !!v ||
                                                                "Campo obrigatório",
                                                            ],
                                                            primary: "",
                                                            icon: "",
                                                            text: "Utm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.openUtmModal = true
                                                            },
                                                          },
                                                        },
                                                        "z-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v("Utm")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "white",
                                                padding: "0.5rem",
                                                "font-weight": "500",
                                              },
                                            },
                                            [_vm._v("Utm Tags")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "", color: "grey" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "z-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "templateTooltipButton",
                                                          staticStyle: {
                                                            "margin-right":
                                                              "8px",
                                                            "margin-bottom":
                                                              "5px",
                                                            padding: "5px",
                                                          },
                                                          attrs: {
                                                            small: "",
                                                            rules: [
                                                              (v) =>
                                                                !!v ||
                                                                "Campo obrigatório",
                                                            ],
                                                            primary: "",
                                                            icon: "mdi-cog",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.sendConfigurationDialog = true
                                                            },
                                                          },
                                                        },
                                                        "z-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "white",
                                                padding: "0.5rem",
                                                "font-weight": "500",
                                              },
                                            },
                                            [_vm._v("Configurações")]
                                          ),
                                        ]
                                      ),
                                      _c("input", {
                                        ref: "file",
                                        staticStyle: { display: "none" },
                                        attrs: { type: "file", accept: ".zip" },
                                        on: { change: _vm.previewFilesV2 },
                                      }),
                                      _c("input", {
                                        ref: "imageFile",
                                        staticStyle: { display: "none" },
                                        attrs: {
                                          type: "file",
                                          multiple: "",
                                          accept: "image/*",
                                        },
                                        on: { change: _vm.uploadImage },
                                      }),
                                      _c("input", {
                                        ref: "imageFileForTemplate",
                                        staticStyle: { display: "none" },
                                        attrs: {
                                          type: "file",
                                          multiple: "",
                                          accept: "image/*",
                                        },
                                        on: {
                                          change: _vm.uploadImagesAndReturn,
                                        },
                                      }),
                                      _c("input", {
                                        ref: "HTMLPickerForTemplate",
                                        staticStyle: { display: "none" },
                                        attrs: { type: "file", accept: "html" },
                                        on: {
                                          change: _vm.uploadHtmlForTemplate,
                                        },
                                      }),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "", color: "grey" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "z-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "templateTooltipButton",
                                                          staticStyle: {
                                                            "margin-right":
                                                              "8px",
                                                            "margin-bottom":
                                                              "5px",
                                                            padding: "5px",
                                                          },
                                                          attrs: {
                                                            small: "",
                                                            rules: [
                                                              (v) =>
                                                                !!v ||
                                                                "Campo obrigatório",
                                                            ],
                                                            primary: "",
                                                            icon: "mdi-image",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.toggleGallery,
                                                                (_vm.galleryDialog = true)
                                                            },
                                                          },
                                                        },
                                                        "z-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "white",
                                                padding: "0.5rem",
                                                "font-weight": "500",
                                              },
                                            },
                                            [_vm._v("Galeria")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "", color: "grey" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "z-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "templateTooltipButton",
                                                          staticStyle: {
                                                            "margin-right":
                                                              "8px",
                                                            "margin-bottom":
                                                              "5px",
                                                            padding: "5px",
                                                          },
                                                          attrs: {
                                                            small: "",
                                                            rules: [
                                                              (v) =>
                                                                !!v ||
                                                                "Campo obrigatório",
                                                            ],
                                                            primary: "",
                                                            "is-loading":
                                                              _vm.uploadingTemplate,
                                                            icon: "mdi-arrow-up",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.openUploadModal = true
                                                            },
                                                          },
                                                        },
                                                        "z-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "white",
                                                padding: "0.5rem",
                                                "font-weight": "500",
                                              },
                                            },
                                            [_vm._v("Carregar template")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "", color: "grey" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "z-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "templateTooltipButton",
                                                          staticStyle: {
                                                            "margin-right":
                                                              "8px",
                                                            "margin-bottom":
                                                              "5px",
                                                            padding: "5px",
                                                          },
                                                          attrs: {
                                                            small: "",
                                                            rules: [
                                                              (v) =>
                                                                !!v ||
                                                                "Campo obrigatório",
                                                            ],
                                                            primary: "",
                                                            icon: "mdi-send",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.openTestModal =
                                                                !_vm.openTestModal
                                                            },
                                                          },
                                                        },
                                                        "z-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "white",
                                                padding: "0.5rem",
                                                "font-weight": "500",
                                              },
                                            },
                                            [_vm._v("Envio Teste")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "", color: "grey" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "z-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "templateTooltipButton",
                                                          staticStyle: {
                                                            "margin-right":
                                                              "8px",
                                                            "margin-bottom":
                                                              "5px",
                                                          },
                                                          attrs: {
                                                            small: "",
                                                            rules: [
                                                              (v) =>
                                                                !!v ||
                                                                "Campo obrigatório",
                                                            ],
                                                            primary: "",
                                                            icon: "mdi-content-save",
                                                            "is-loading":
                                                              _vm.loadingTemplate,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.exportHtml(),
                                                                _vm.saveDesign
                                                            },
                                                          },
                                                        },
                                                        "z-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "white",
                                                padding: "0.5rem",
                                                "font-weight": "500",
                                              },
                                            },
                                            [_vm._v("Salvar template")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("v-col", { attrs: { cols: 12 } }, [
                _c(
                  "div",
                  { staticClass: "caixa" },
                  [
                    _vm.canShow
                      ? _c("EmailEditor", {
                          ref: "emailEditor",
                          staticStyle: {
                            height: "85vh",
                            "padding-top": "20px",
                            "min-width": "0px !important",
                          },
                          attrs: {
                            appearance: _vm.appearance,
                            "min-height": _vm.minHeight,
                            "project-id": _vm.projectId,
                            locale: _vm.locale,
                            tools: _vm.tools,
                            options: _vm.options,
                          },
                          on: {
                            load: _vm.editorLoaded,
                            ready: _vm.editorReady,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showInformations,
              expression: "!showInformations",
            },
          ],
          staticClass: "wrapper no-data",
        },
        [_c("div", { staticClass: "wrapper__card" }, [_c("z-loading")], 1)]
      ),
      _c("z-card-modal", {
        attrs: { width: "400", title: "Tags disponíveis para uso" },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cards-audience",
                    staticStyle: {
                      gap: "1rem",
                      padding: "1rem 0",
                      "flex-wrap": "wrap",
                      color: "333333",
                    },
                  },
                  _vm._l(_vm.tags, function (item, index) {
                    return _c("div", { key: index }, [
                      _c("span", [
                        _vm._v(" Tag para " + _vm._s(item.text) + ": "),
                        _c("strong", [_vm._v(_vm._s(item.value))]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.showTagsModal,
          callback: function ($$v) {
            _vm.showTagsModal = $$v
          },
          expression: "showTagsModal",
        },
      }),
      _c(
        "v-dialog",
        {
          staticStyle: {
            "border-radius": "15px !important",
            "z-index": "9999",
          },
          attrs: { width: "800" },
          model: {
            value: _vm.galleryDialog,
            callback: function ($$v) {
              _vm.galleryDialog = $$v
            },
            expression: "galleryDialog",
          },
        },
        [
          _c("GalleryModal", {
            attrs: { "image-repo-prop": _vm.repoImages },
            on: { close: _vm.closeGallery },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "800" },
          model: {
            value: _vm.openVariablesModal,
            callback: function ($$v) {
              _vm.openVariablesModal = $$v
            },
            expression: "openVariablesModal",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal",
              staticStyle: { "z-index": "9999 !important" },
            },
            [
              _c("div", { staticClass: "modal__header" }, [
                _c("div", { staticClass: "modal__header__title" }, [
                  _c("span", [_vm._v("Variaveis ")]),
                  _c("br"),
                ]),
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c(
                      "v-icon",
                      {
                        on: {
                          click: function ($event) {
                            _vm.openVariablesModal = false
                          },
                        },
                      },
                      [_vm._v("mdi-close")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "modal__body" }, [
                _c("div", { staticClass: "variableTableDiv" }, [
                  _c("table", { staticClass: "variableTable" }, [
                    _c("tr", [
                      _c("th", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Variável"),
                      ]),
                      _c("th", [_vm._v("Valor padrão")]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.variables, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(item.value))]),
                          _c(
                            "td",
                            [
                              _c("z-input", {
                                attrs: { hideDetails: true },
                                model: {
                                  value: _vm.defaultValues[item.value],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.defaultValues, item.value, $$v)
                                  },
                                  expression: "defaultValues[item.value]",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "modal--actions mt-3" },
                  [
                    _c("z-btn", {
                      attrs: { text: "Ok", primary: "", rounded: "" },
                      on: {
                        click: function ($event) {
                          _vm.openVariablesModal = false
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "800" },
          model: {
            value: _vm.openUtmModal,
            callback: function ($$v) {
              _vm.openUtmModal = $$v
            },
            expression: "openUtmModal",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal",
              staticStyle: { "z-index": "9999 !important" },
            },
            [
              _c("div", { staticClass: "modal__header" }, [
                _c("div", { staticClass: "modal__header__title" }, [
                  _c("span", [_vm._v("Utm Tags")]),
                  _c("br"),
                ]),
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c(
                      "v-icon",
                      {
                        on: {
                          click: function ($event) {
                            _vm.openUtmModal = false
                          },
                        },
                      },
                      [_vm._v("mdi-close")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "modal__body" }, [
                _c("div", { staticClass: "variableTableDiv" }, [
                  _c("table", { staticClass: "variableTable" }, [
                    _c("tr", [
                      _c("th", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Tag"),
                      ]),
                      _c("th", [_vm._v("Valor")]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.utmNames, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(item))]),
                          _c(
                            "td",
                            [
                              _c("z-input", {
                                attrs: { hideDetails: true },
                                model: {
                                  value: _vm.templateToSave[item],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.templateToSave, item, $$v)
                                  },
                                  expression: "templateToSave[item]",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "modal--actions mt-3" },
                  [
                    _c("z-btn", {
                      attrs: { text: "Ok", primary: "", rounded: "" },
                      on: {
                        click: function ($event) {
                          _vm.openVariablesModal = false
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm.sendConfigurationDialog
        ? _c(
            "v-dialog",
            {
              staticStyle: {
                "border-radius": "15px !important",
                "z-index": "9999",
              },
              attrs: { width: "500" },
              model: {
                value: _vm.sendConfigurationDialog,
                callback: function ($$v) {
                  _vm.sendConfigurationDialog = $$v
                },
                expression: "sendConfigurationDialog",
              },
            },
            [
              _c("div", { staticClass: "modal_sender" }, [
                _c("div", { staticClass: "modal_sender__header" }, [
                  _c("div", { staticClass: "modal_sender__header__title" }, [
                    _c("span", [_vm._v("Informações do remetente ")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "action" },
                    [
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function ($event) {
                              _vm.sendConfigurationDialog = false
                            },
                          },
                        },
                        [_vm._v("mdi-close")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "modal_sender__body" },
                  [
                    _c(
                      "v-form",
                      { ref: "formremetente" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticStyle: {
                                  "margin-bottom": "15px",
                                  padding: "0px 5px",
                                  "padding-top": "8px",
                                },
                                attrs: { sm: "12", md: "12", lg: "12" },
                              },
                              [
                                _c("z-input", {
                                  attrs: {
                                    label: "Nome de envio",
                                    type: "text",
                                  },
                                  model: {
                                    value: _vm.templateToSave.senderName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.templateToSave,
                                        "senderName",
                                        $$v
                                      )
                                    },
                                    expression: "templateToSave.senderName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticStyle: {
                                  "margin-bottom": "15px",
                                  padding: "0px 5px",
                                },
                                attrs: { sm: "5", md: "5", lg: "5" },
                              },
                              [
                                _c("z-input", {
                                  attrs: {
                                    rules: [(v) => !!v || "Campo obrigatório"],
                                    label: "Email de envio",
                                    type: "text",
                                  },
                                  model: {
                                    value: _vm.templateToSave.emailSender,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.templateToSave,
                                        "emailSender",
                                        $$v
                                      )
                                    },
                                    expression: "templateToSave.emailSender",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticStyle: {
                                  "margin-bottom": "15px",
                                  padding: "0px 5px",
                                },
                                attrs: { sm: "6", md: "6", lg: "6" },
                              },
                              [
                                _c("z-select", {
                                  attrs: {
                                    label: "Dominio",
                                    rules: [(v) => !!v || "Campo obrigatório"],
                                    "hide-details": true,
                                    items: _vm.emailDomains,
                                    type: "text",
                                  },
                                  model: {
                                    value: _vm.templateToSave.emailDomain,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.templateToSave,
                                        "emailDomain",
                                        $$v
                                      )
                                    },
                                    expression: "templateToSave.emailDomain",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticStyle: {
                                  "margin-bottom": "15px",
                                  padding: "0px 5px",
                                },
                                attrs: { sm: "12", md: "12", lg: "12" },
                              },
                              [
                                _c("z-input", {
                                  attrs: {
                                    rules: _vm.emailRules,
                                    label: "Responder para",
                                    type: "text",
                                  },
                                  model: {
                                    value: _vm.templateToSave.replyTo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.templateToSave,
                                        "replyTo",
                                        $$v
                                      )
                                    },
                                    expression: "templateToSave.replyTo",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticStyle: {
                                  "margin-bottom": "15px",
                                  padding: "0px 5px",
                                },
                                attrs: { sm: "12", md: "12", lg: "12" },
                              },
                              [
                                _c("z-input", {
                                  attrs: {
                                    rules: [(v) => !!v || "Campo obrigatório"],
                                    label: "Assunto do e-mail",
                                    type: "text",
                                  },
                                  model: {
                                    value: _vm.templateToSave.emailSubject,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.templateToSave,
                                        "emailSubject",
                                        $$v
                                      )
                                    },
                                    expression: "templateToSave.emailSubject",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticStyle: {
                                  "margin-bottom": "5px",
                                  padding: "0px 5px",
                                },
                                attrs: { sm: "12", md: "12", lg: "12" },
                              },
                              [
                                _c("z-checkbox", {
                                  attrs: { label: "Permitir Optout" },
                                  model: {
                                    value: _vm.templateToSave.allowOptout,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.templateToSave,
                                        "allowOptout",
                                        $$v
                                      )
                                    },
                                    expression: "templateToSave.allowOptout",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "mt-10", attrs: { justify: "end" } },
                          [
                            _c("z-btn", {
                              staticStyle: {
                                "margin-right": "8px",
                                "margin-bottom": "5px",
                              },
                              attrs: {
                                small: "",
                                text: "Confirmar",
                                primary: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmRemetenteDataModal()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "570" },
          model: {
            value: _vm.openTestModal,
            callback: function ($$v) {
              _vm.openTestModal = $$v
            },
            expression: "openTestModal",
          },
        },
        [
          _c("TestModal", {
            attrs: {
              "choosen-connection-prop": _vm.choosenConnection,
              firstData: _vm.firstData,
              defaultValues: _vm.defaultValues,
              "template-to-save-prop": _vm.templateToSave,
              "email-information-prop": _vm.$refs.emailEditor,
            },
            on: {
              close: function ($event) {
                _vm.openTestModal = false
              },
              onErroInInformations: function ($event) {
                return _vm.$refs.form.validate()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "570" },
          model: {
            value: _vm.openTagsModal,
            callback: function ($$v) {
              _vm.openTagsModal = $$v
            },
            expression: "openTagsModal",
          },
        },
        [
          _c("TagsModal", {
            attrs: { tags: _vm.tags },
            on: {
              close: function ($event) {
                _vm.openTagsModal = false
              },
            },
          }),
        ],
        1
      ),
      _c("z-card-modal", {
        attrs: { title: "Importação de template de e-mail", width: "800" },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cards-audience",
                    style: _vm.cardAudienceStyle2,
                  },
                  [
                    _vm.imagesForNewTemplate &&
                    _vm.imagesForNewTemplate.length === 0
                      ? _c(
                          "div",
                          {
                            staticClass: "cards-audience__item",
                            style: _vm.cardAudienceStyle,
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "header",
                                staticStyle: { "text-align": "center" },
                                on: { click: _vm.openImagePickerForTemplate },
                              },
                              [
                                _c(
                                  "figure",
                                  {
                                    staticStyle: {
                                      width: "30px",
                                      height: "30px",
                                      margin: "0 auto",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%: height: 100%",
                                        "object-fit": "contain",
                                      },
                                      attrs: { src: "/icons/upload-icon.svg" },
                                    }),
                                  ]
                                ),
                                _c(
                                  "strong",
                                  {
                                    staticStyle: {
                                      "text-transform": "uppercase",
                                      "font-size": "0.85em",
                                    },
                                  },
                                  [_vm._v(" Upload Imagens ")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "body",
                                staticStyle: {
                                  "text-align": "center",
                                  "font-size": "1em",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v("Selecione "),
                                  _c("strong", [_vm._v("as imagens")]),
                                  _vm._v(" do seu template"),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "cards-audience__item",
                            style: _vm.cardAudienceStyle,
                            on: { click: _vm.openHTMLPickerForTemplate },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "header",
                                staticStyle: { "text-align": "center" },
                              },
                              [
                                _c(
                                  "figure",
                                  {
                                    staticStyle: {
                                      width: "30px",
                                      height: "30px",
                                      margin: "0 auto",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%: height: 100%",
                                        "object-fit": "contain",
                                      },
                                      attrs: { src: "/icons/upload-icon.svg" },
                                    }),
                                  ]
                                ),
                                _c(
                                  "strong",
                                  {
                                    staticStyle: {
                                      "text-transform": "uppercase",
                                      "font-size": "0.85em",
                                    },
                                  },
                                  [_vm._v(" Upload HTML ")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "body",
                                staticStyle: {
                                  "text-align": "center",
                                  "font-size": "1em",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v("Selecione o "),
                                  _c("strong", [_vm._v("arquivo .HTML")]),
                                  _vm._v(" do seu template"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "action d-flex justify-end py-2" },
                  [
                    _c("z-btn", {
                      attrs: {
                        large: "",
                        text: "cancelar",
                        isLoading: _vm.submitingFile,
                      },
                      on: {
                        click: function ($event) {
                          _vm.openUploadModal = false
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.openUploadModal,
          callback: function ($$v) {
            _vm.openUploadModal = $$v
          },
          expression: "openUploadModal",
        },
      }),
      _c("z-card-modal", {
        attrs: {
          width: "600",
          title:
            "tem certeza que deixar criar uma nova jornada com esse template?",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "fast-journey-modal" }, [
                  _c("div", { staticClass: "pt-3 pb-5" }, [
                    _c("span", [
                      _vm._v(" Template selecionado: "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.templateToSave.templateName)),
                      ]),
                    ]),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        " Selecione uma audiência abaixo para dar proseguimento "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-form",
                        { ref: "confirmFastForm" },
                        [
                          _c("z-autocomplete", {
                            attrs: {
                              label: "Audiência",
                              items: _vm.audiencesList,
                              itemText: "name",
                              placeholder: "Pesquisar",
                              type: "text",
                              hideDetails: true,
                              returnObject: "",
                              rules: [(v) => !!v || "Campo obrigatório"],
                            },
                            model: {
                              value: _vm.choosenAudience,
                              callback: function ($$v) {
                                _vm.choosenAudience = $$v
                              },
                              expression: "choosenAudience",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pt-5 pb-1 d-flex justify-end" },
                    [
                      _c("z-btn", {
                        staticClass: "mr-2",
                        attrs: { text: "Cancelar", rounded: "" },
                        on: {
                          click: function ($event) {
                            _vm.confirmationFastJourney = false
                          },
                        },
                      }),
                      _c("z-btn", {
                        attrs: {
                          text: "Confirmar",
                          primary: "",
                          rounded: "",
                          disabled: !_vm.choosenAudience,
                        },
                        on: { click: _vm.createSimpleJourney },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.confirmationFastJourney,
          callback: function ($$v) {
            _vm.confirmationFastJourney = $$v
          },
          expression: "confirmationFastJourney",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }