<template>
    <!-- @click.right.prevent="$refs.contextMenu.open($event, 'data')" -->
  <figure
    :style="{
      border: imagesToDeleteProp.includes(imagePath) ? '3px dashed #D21938' : '',
    }"
  >
    <img :src="imagePath" @click="copy" />

    <span v-show="copied">Copiado!</span>

    <!-- <div v-show="imagesToDeleteProp.includes(imagePath)" class="box-to-remove">
      <v-icon color="#D21938" @click="$emit('removeFromDelete', imagePath)"
        >mdi-close</v-icon
      >
    </div> -->

    <div v-if="!isToSelectManyProp" class="remove-button">
      <v-icon small color="white" @click.self="$emit('removeSingle', imagePath)">mdi-close</v-icon>
    </div>

    <!-- <z-context-menu
      ref="contextMenu"
      :options="optionsMenu"
      @click="onClickContextAction"
    /> -->
  </figure>
</template>

<script>
export default {
  data() {
    return {
      test: 0,
      copied: false,
      optionsMenu: [{ action: "delete", text: "Remover", value: 0 }],
    };
  },
  props: {
    imagePath: { type: String, default: null },
    imagesToDeleteProp: { type: [Array, String] },
    isToSelectManyProp: {type: Boolean}
  },
  methods: {
    testss() {
      console.log("bla bla bla");
    },
    copy() {
      this.$emit("copied", this.imagePath);
      this.copied = true;

      setTimeout(() => {
        this.copied = false;
      }, 1300);
    },
    onClickContextAction({ option }) {
      switch (option.value) {
        case 0:
          this.$emit("removeItem", this.imagePath);
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
figure {
  width: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      // scale: 1.1;
    }
  }

  span {
    position: absolute;
    bottom: 3px;
    right: 0;
    display: block;
    background: #1976d2;
    color: white;
    font-size: 0.65rem;
    padding: 0.2rem;
    border-radius: 5px;
  }

  .remove-button {
    position: absolute;
    top: -10px;
    right: 5px;
    height: 20px;
    width: 20px;
    background: #d21938;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: none;
  }

  .box-to-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
  }

  &:hover {
    .remove-button {
      display: flex;
    }
  }
}
</style>
