<template>
  <div class="modal" style="z-index: 9999 !important">
    <div class="modal__header">
      <div class="modal__header__title">
        <span>Envio teste </span>
        <br />
        <small>Insira os emails dos destinatários abaixo:</small>
      </div>
      <div class="action">
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </div>
    </div>

    <div class="modal__body">
      <v-form ref="form">
        <v-row>
          <v-col cols="12">
            <v-combobox
              v-model="model"
              hide-selected
              dense
              multiple
              small-chips
              outlined
              small
              :search-input.sync="search"
              :items="items"
              :filter="filter"
              :hide-no-data="!search"
              :rules="[required]"
              :hideDetails="true"
            >
              <template v-slot:no-data>
                <v-list-item class="px-1">
                  <span class="subheading mr-1">Enviar Para: </span>
                  <v-chip :color="`#1976D2`" label small>
                    <span class="px-1" style="color: white">{{ search }}</span>
                  </v-chip>
                </v-list-item>
              </template>

              <template
                v-slot:selection="{ index, attrs, item, parent, selected }"
              >
                <v-chip
                  v-if="item === Object(item)"
                  v-bind="attrs"
                  :color="`${item.color}`"
                  :input-value="selected"
                  label
                  small
                  class="px-1"
                >
                  <span class="pr-2" style="color: white">
                    {{ item.text }}
                  </span>
                  <v-icon
                    small
                    @click="
                      parent.selectItem(item), removeFromList(item, index)
                    "
                    color="white"
                  >
                    $close_rounded
                  </v-icon>
                </v-chip>
              </template>

              <template v-slot:item="{ index, item }">
                <v-text-field
                  v-if="editing === item"
                  v-model="editing.text"
                  autofocus
                  flat
                  background-color="transparent"
                  hide-details
                  solo
                  @keyup.enter="edit(index, item)"
                >
                </v-text-field>

                <v-chip
                  v-else
                  :color="`${item.color} lighten-3`"
                  class="px-1"
                  dark
                  label
                  small
                >
                  {{ item.text }}
                </v-chip>

                <v-spacer></v-spacer>

                <v-list-item-action @click.stop>
                  <v-btn icon @click.stop.prevent="edit(index, item)">
                    <v-icon>{{
                      editing !== item ? "mdi-pencil" : "mdi-check"
                    }}</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-combobox>
            <!-- 
            <z-input
              v-model="email"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              :hideDetails="true"
              placeholder="E-mails separados por vírgula"
              type="text"
          />
           -->
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12">
            <z-input
              :rules="[(v) => !!v || 'Campo obrigatório']"
              :hideDetails="true"
              v-model="mailSubject"
              placeholder="Assunto do e-mail"
              type="text"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <z-input
              :rules="[(v) => !!v || 'Campo obrigatório']"
              :hideDetails="true"
              placeholder="Remetente"
              v-model="mailSender"
              type="text"
            />
          </v-col>
        </v-row> -->
      </v-form>
    </div>

    <div class="modal--actions mt-13">
      <z-btn small text="Cancelar" @click="$emit('close')" />
      <z-btn
        small
        primary
        text="Concluir"
        :is-loading="isLoading"
        @click="submit"
      />
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
import moment from "moment";
export default {
  props: {
    templateToSaveProp: { type: Object, default: null },
    choosenConnectionProp: { type: Object, edfault: null },
    defaultValues: { type: Object, default: null },
    emailInformationProp: { type: Object, default: null },
    firstData: { type: Array, default: null },
  },
  data() {
    return {
      nonce: 1,
      y: 0,
      x: 0,
      search: null,
      isLoading: false,
      email: "",
      http: new ApiClient(),
      // items: [{ header: "Selecione ou crie um e-mail" }],
      items: [],
      model: [],
      activator: null,
      attach: null,
      colors: [
        "#1976D2",
        "#1976D2",
        "#1976D2",
        "#1976D2",
        "#1976D2",
        "#1976D2",
      ],
      editing: null,
      databaseFields: [],
      variables: [],
      editingIndex: -1,
    };
  },
  watch: {
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: "blue",
          };

          this.items.push(v);

          this.nonce++;
        }

        return v;
      });
    },
  },
  methods: {
    removeFromList(item, index) {
      this.items.splice(index, 1);
    },
    required(value) {
      if (value instanceof Array && value.length == 0) {
        return "Campo obrigatório.";
      }
      return !!value || "Campo obrigatório.";
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    treatFirstData() {
      const tempData = [];
      if (this.firstData) {
        this.firstData.forEach((x) => {
          const newObj = Object.fromEntries(
            Object.entries(x).map(([k, v]) => [k.toLowerCase(), v])
          );
          tempData.push(newObj);
        });
      }

      this.firstData = tempData;
    },
    transformString(text, data) {
      if (data) {
        const keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
          if (data[keys[i]])
            text = text.replaceAll(
              new RegExp(`\\$${keys[i]}`, "ig"),
              data[keys[i]]
            );
        }
      } else if (this.firstData && this.firstData.length > 0) {
        console.log(this.firstData);

        if (this.firstData && this.firstData.length > 0) {
          for (let j = 0; j < this.firstData.length; j++) {
            const keys = Object.keys(this.firstData[j]);

            for (let i = 0; i < keys.length; i++) {
              let fieldConfig = this.databaseFields[keys[i]];
              let fieldValue = "";

              if (!this.firstData[j][keys[i]] || !fieldConfig) continue;

              if (fieldConfig.type === "decimal") {
                fieldValue = parseFloat(this.firstData[j][keys[i]])
                  .toFixed(2)
                  .replace(".", ",");
              } else if (fieldConfig.type === "date") {
                fieldValue = moment
                  .utc(this.firstData[j][keys[i]])
                  .format("DD/MM/yyyy");
              } else if (fieldConfig.type === "datetime") {
                fieldValue = moment
                  .utc(this.firstData[j][keys[i]])
                  .format("DD/MM/yyyy hh:mm");
              } else {
                fieldValue = this.firstData[j][keys[i]];
              }

              //eslint-disable-next-line
              let patternString = "\\$" + keys[i] + "(?![a-zA-Z])";
              let pattern = new RegExp(patternString, "gi");

              if (fieldValue) text = text.replace(pattern, fieldValue);
            }
          }
        }
      }

      if (this.defaultValues) {
        const keys = Object.keys(this.defaultValues);

        console.log(keys);

        for (let i = 0; i < keys.length; i++) {
          let patternString = "\\$" + keys[i] + "(?![a-zA-Z])";
          let pattern = new RegExp(patternString, "gi");
          text = text.replace(pattern, this.defaultValues[keys[i]]);
        }
      }

      return text;
    },
    formatEmail() {
      let emails = [];
      if (this.model.length) {
        this.model.forEach((d) => {
          emails.push(`${d.text}`);
        });
      }

      return emails;
    },
    getEmailQuery() {
      let S = "";
      if (this.model.length) {
        this.model.forEach((d) => {
          S += `'${d.text}',`;
        });
      }

      return S.substring(0, S.length - 1);
    },
    submit() {
      if (
        this.$refs.form.validate() &&
        this.templateToSaveProp.emailSender &&
        this.templateToSaveProp.emailSubject
      ) {
        // if (this.templateToSaveProp && this.choosenConnectionProp) {

        this.emailInformationProp.editor.exportHtml(async (data) => {
          let emails = this.formatEmail();
          this.isLoading = true;
          let personalizedData = [];
          await this.http
            .get(
              `dbConfiguration/getFirstData/${
                this.choosenConnectionProp.id
              }?email=${this.getEmailQuery()}`
            )
            .then((resp) => {
              personalizedData = resp;
            })
            .catch((err) => {
              throw err;
            });

          await this.http
            .get(
              `templatesConfiguration/getVariableFields/${this.choosenConnectionProp.id}`
            )
            .then((resp) => {
              this.databaseFields = resp;
              let keys = Object.keys(resp);
              this.variables = keys.map((key) => {
                return { value: key };
              });
            })
            .catch((err) => {
              throw err;
            });

          let success = true;
          for (let i = 0; i < emails.length; i++) {
            let OBJ = { content: {} };
            OBJ.email = emails[i];
            OBJ.subject = this.templateToSaveProp.emailSubject;
            OBJ.replyTo = this.templateToSaveProp.replyTo;
            OBJ.mailSender =
              this.templateToSaveProp.emailSender +
              this.templateToSaveProp.emailDomain;

            OBJ.senderName = this.templateToSaveProp.senderName;
            OBJ.content.message = JSON.stringify(data);

            let personData = personalizedData.find((x) => x.email == OBJ.email);

            OBJ.subject = this.transformString(OBJ.subject, personData);

            OBJ.content.message = this.transformString(
              OBJ.content.message,
              personData
            );
            //   console.log(OBJ.content.message);
            OBJ.content.description = "";
            OBJ.content.databaseConfigId = this.choosenConnectionProp?.id;

            try {
              await this.http.post(
                `templatesConfiguration/sendEmailTemplateTest`,
                {
                  ...OBJ,
                }
              );
            } catch (error) {
              this.$toast.error(`${error.body.detail}`);
              success = false;
              throw error;
            }
          }

          if (success) {
            this.$toast.success("Sucesso!");
            this.$emit("close");
          }
          this.isLoading = false;
        });

        // } else {
        //   this.$toast.warning("Preencha todas as informações necessárias");
        //   this.$emit("onErroInInformations");
        // }
      } else {
        this.$toast.error(`Preencha todos os campos do template`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  padding: $z-s-1;
  border-radius: 15px;
  color: $z-black-1;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $z-s-1;

    &__title {
      span {
        font-weight: 600;
        text-transform: uppercase;
        line-height: 15px;
        font-size: 1rem;
      }
    }
  }

  &__body {
    color: #4e4e4e;
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
