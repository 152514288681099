import { render, staticRenderFns } from "./ImageGallery.vue?vue&type=template&id=26f2c1e6&scoped=true&"
import script from "./ImageGallery.vue?vue&type=script&lang=js&"
export * from "./ImageGallery.vue?vue&type=script&lang=js&"
import style0 from "./ImageGallery.vue?vue&type=style&index=0&id=26f2c1e6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26f2c1e6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kalil/Documents/projects/zoox/data-to-act-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('26f2c1e6')) {
      api.createRecord('26f2c1e6', component.options)
    } else {
      api.reload('26f2c1e6', component.options)
    }
    module.hot.accept("./ImageGallery.vue?vue&type=template&id=26f2c1e6&scoped=true&", function () {
      api.rerender('26f2c1e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Pages/Templates/Email/Component/ImageGallery.vue"
export default component.exports