var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal", staticStyle: { "z-index": "9999 !important" } },
    [
      _c("div", { staticClass: "modal__header" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "action" },
          [
            _c(
              "v-icon",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("mdi-close")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "modal__body" },
        [
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-combobox", {
                        attrs: {
                          "hide-selected": "",
                          dense: "",
                          multiple: "",
                          "small-chips": "",
                          outlined: "",
                          small: "",
                          "search-input": _vm.search,
                          items: _vm.items,
                          filter: _vm.filter,
                          "hide-no-data": !_vm.search,
                          rules: [_vm.required],
                          hideDetails: true,
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            _vm.search = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.search = $event
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "no-data",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item",
                                  { staticClass: "px-1" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "subheading mr-1" },
                                      [_vm._v("Enviar Para: ")]
                                    ),
                                    _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          color: `#1976D2`,
                                          label: "",
                                          small: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "px-1",
                                            staticStyle: { color: "white" },
                                          },
                                          [_vm._v(_vm._s(_vm.search))]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "selection",
                            fn: function ({
                              index,
                              attrs,
                              item,
                              parent,
                              selected,
                            }) {
                              return [
                                item === Object(item)
                                  ? _c(
                                      "v-chip",
                                      _vm._b(
                                        {
                                          staticClass: "px-1",
                                          attrs: {
                                            color: `${item.color}`,
                                            "input-value": selected,
                                            label: "",
                                            small: "",
                                          },
                                        },
                                        "v-chip",
                                        attrs,
                                        false
                                      ),
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "pr-2",
                                            staticStyle: { color: "white" },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.text) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              small: "",
                                              color: "white",
                                            },
                                            on: {
                                              click: function ($event) {
                                                parent.selectItem(item),
                                                  _vm.removeFromList(
                                                    item,
                                                    index
                                                  )
                                              },
                                            },
                                          },
                                          [_vm._v(" $close_rounded ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function ({ index, item }) {
                              return [
                                _vm.editing === item
                                  ? _c("v-text-field", {
                                      attrs: {
                                        autofocus: "",
                                        flat: "",
                                        "background-color": "transparent",
                                        "hide-details": "",
                                        solo: "",
                                      },
                                      on: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.edit(index, item)
                                        },
                                      },
                                      model: {
                                        value: _vm.editing.text,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editing, "text", $$v)
                                        },
                                        expression: "editing.text",
                                      },
                                    })
                                  : _c(
                                      "v-chip",
                                      {
                                        staticClass: "px-1",
                                        attrs: {
                                          color: `${item.color} lighten-3`,
                                          dark: "",
                                          label: "",
                                          small: "",
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(item.text) + " ")]
                                    ),
                                _c("v-spacer"),
                                _c(
                                  "v-list-item-action",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.edit(index, item)
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.editing !== item
                                                ? "mdi-pencil"
                                                : "mdi-check"
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.model,
                          callback: function ($$v) {
                            _vm.model = $$v
                          },
                          expression: "model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal--actions mt-13" },
        [
          _c("z-btn", {
            attrs: { small: "", text: "Cancelar" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          }),
          _c("z-btn", {
            attrs: {
              small: "",
              primary: "",
              text: "Concluir",
              "is-loading": _vm.isLoading,
            },
            on: { click: _vm.submit },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header__title" }, [
      _c("span", [_vm._v("Envio teste ")]),
      _c("br"),
      _c("small", [_vm._v("Insira os emails dos destinatários abaixo:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }